function buttonSlideEvent() {
	$('.btn-slide').click(function () {
		$(this).toggleClass('open');
		$(this).siblings('.btn-slide-item').slideToggle(350, function () {
			$(this).toggleClass('open');
		});
	});
}
function sitemapSlide() {
	$('.footer-sitemap-button').click(function () {
		$(this).toggleClass('open');
		$('.footer-sitemap-section').slideToggle();
	});
}

function tabEvents() {
	$('.btn-tab').click(function () {
		if (!$(this).hasClass('active')) {
			$(this).siblings('.active').removeClass('active');
			$(this).addClass('active');
			var sectionId = $(this).data('section');
			$('#' + sectionId + ' .tab-item.active').fadeOut(350, function () {
				$(this).removeClass('active');
			});
			var tabId = $(this).data('tab');
			setTimeout(function () {
				$('#' + tabId).fadeIn(350, function () {
					$(this).addClass('active');
				});
			}, 290);
		}
	});
}

function mobileMenu() {
	var body = document.getElementsByTagName('body')[0];
	var html = document.getElementsByTagName('html')[0];
	$('.menu-toggle').click(function () {
		$('.header-nav .header-menu').slideToggle(350);
		$('.header-nav').toggleClass('open');
		$('.header-container').toggleClass('open');
		if ($(window).width() < 1375) {
			if (body.style.overflow === 'scroll' && html.style.overflow === 'scroll' || body.style.overflow === '' && html.style.overflow === '') {
				body.style.overflow = 'hidden';
				html.style.overflow = 'hidden';
			}
			else if (body.style.overflow === 'hidden' && html.style.overflow === 'hidden') {
				body.style.overflow = 'scroll';
				html.style.overflow = 'scroll';
			}
		}
	});
	$('.sub-menu-parent').click(function () {
		if ($(window).width() < 1375) {
			$(this).toggleClass('open');
			$(this).children('.sub-menu').slideToggle(350);
		}
	});
}

function popupFull() {
	$('html, body').css('overflow-y', 'hidden');
	$('.popup-back, .popup-full .popup-x').click(function () {
		$('.popup-full').fadeOut();
		setTimeout(function () {
			$('html, body').css('overflow-y', 'visible');
		}, 320);
	});
}
function popupSmall() {
	$('.popup-small .popup-x').click(function () {
		$('.popup-small').fadeOut();
	});
}

var currentSize;
var newSize;
function initCropSwap() {
	cropSwap();
	$(window).resize(cropSwap);
}

function cropSwap() {
	if ($(window).width() > 1374) {
		newSize = 'lg';
	} else if ($(window).width() > 767) {
		newSize = 'md';
	} else {
		newSize = 'sm';
	}

	if (newSize !== currentSize) {
		currentSize = newSize;
		$('[data-crop-swap]').each(function () {
			var $this = $(this);
			var imageUrl = $this.data(currentSize + '-image');
			$this.css('background-image', 'url(' + imageUrl + ')');
			$this.attr('data-size', newSize);
		});
	}
}

function initEmergencyBanner() {
	$(window).resize(function() {
		resizeEmergencyBanner();
	});
}

function resizeEmergencyBanner() {
	var padding = calculateTopPadding();
	if (padding > 0) {
		var container = $('body').find('.container-max,.wftw-container').eq(0);
		container.css("padding-top", padding + "px");
	}
}

function calculateTopPadding() {

	var banner = $('.emergency-appeals-banner-back');
	var header = $('.basic-header');
	var bannerHeight = banner.is(':visible') ? banner.outerHeight() : 0;
	var headerHeight = header.outerHeight();
	var padding = headerHeight + bannerHeight;
	return padding;
	
}

function initScrollToAnchorLinks() {
	//if there's already a anchor in the url, we need to adjust the scroll position
	var hash = window.location.hash;
	if (hash !== '') {
		var target = $(hash);
		if (target.length > 0) {
			$('html,body').stop().animate({
				scrollTop: target.offset().top - calculateTopPadding() - 20
			}, 'slow');
		};
	}

	$("a[href^=\\#]").click(function (e) {
		e.preventDefault();
		var dest = $(this).attr('href');
		if ($(dest).length > 0) {
			history.pushState(null, null, dest);
			$('html,body').animate({
				scrollTop: $(dest).offset().top - calculateTopPadding() - 20
			}, 'slow');
		}
	});
}

$(document).ready(function () {
	if ($('.btn-slide').length) {
		buttonSlideEvent();
	}
	if ($('.footer-sitemap-button').length) {
		sitemapSlide();
	}
	if ($('.btn-tab').length) {
		tabEvents();
	}
	if ($('.popup-full').length) {
		popupFull();
	}
	if ($('.popup-small').length) {
		popupSmall();
	}
	if ($('[data-crop-swap]').length) {
		initCropSwap();
	}

	resizeEmergencyBanner();
	initEmergencyBanner();

	mobileMenu();

	initScrollToAnchorLinks();
});