var tickers = {

	
	init: function () {
		$('[data-ticker]:not(.js-tickers-clock-done)').each(function () {
			var $this = $(this);

			var duration = typeof $this.attr('data-duration') !== 'undefined' && parseInt($this.attr('data-duration'), 10) > 0 ? parseInt($this.attr('data-duration'), 10) : 200;
			var reverse = typeof $this.attr('data-reverse') !== 'undefined' && $this.attr('data-reverse') === 'true';
			var forceLoop = typeof $this.attr('data-force-loop') !== 'undefined' && $this.attr('data-force-loop') === 'true';
			var startPaused = typeof $this.attr('data-start-paused') !== 'undefined' && $this.attr('data-start-paused') === 'true';

			$this.jConveyorTicker({
				//specify the time (in milliseconds) the animation takes to move 10 pixels
				anim_duration: duration,
				//enable the use of the reverse animation trigger html element
				reverse_elm: reverse,
				//force the initialization even if the items are too small in total width
				force_loop: forceLoop,
				start_paused: startPaused
			});

			$this.addClass('js-ticker-done');
		});
	}
};
$(function () {
	tickers.init();




});