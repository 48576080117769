//by default validator ignores hidden fields... this ensures everything gets validated
$.validator.setDefaults({ ignore: null });

//sorts out the css on the form wrappers for the jquery unobtrusive validation
(function ($) {
	var defaultOptions = {
		validClass: 'form-success',
		errorClass: 'form-error',
		highlight: function (element, errorClass, validClass) {
			$(element).closest(".field-wrapper")
				.removeClass(validClass)
				.addClass(errorClass);
		},
		unhighlight: function (element, errorClass, validClass) {
			$(element).closest(".field-wrapper")
				.removeClass(errorClass)
				.addClass(validClass);
		}
	};

	$.validator.setDefaults(defaultOptions);

	$.validator.unobtrusive.options = {
		errorClass: defaultOptions.errorClass,
		validClass: defaultOptions.validClass
	};
})(jQuery);

var validation = {
	init: function () {
		validation.wrapperCheck();
	},
	wrapperCheck: function () {
		//if the js validation has passed and the form has submitted but there are errors on the server side,
		//the classes are only applied to the inputs, not the wrappers...
		$('.input-validation-error:not(.js-validation-check-done)').each(function () {
			var $this = $(this);
			$this.closest(".field-wrapper").addClass('input-error');
			$this.addClass('js-validation-check-done');
		});
	}
};
$(function () {
	validation.init();
});