var loadingModal = {
	$element: null,
	preInit: function () {
		var loadingHtml = '<div id="loadingModal" style="display: none;">' +
			'<div class="spinner">' +
			'<i class="las la-circle-notch"></i>' +
			'</div>' +
			'<div class="fade"></div>' +
			'</div>';
		$('body').append(loadingHtml);
		loadingModal.$element = $('#loadingModal');
	},
	show: function () {
		loadingModal.$element.show();
	},
	hide: function () {
		loadingModal.$element.hide();
	}
};

$(function () {
	loadingModal.preInit();
});