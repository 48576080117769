/*
* call init to do a global init using passed in options
* or do a init per sharing type using parameters or passed in options
*
* options:
* shareViaEmailEndpoint = endpoint to send share via email request to
* shareViaEmailElementId = element user will click to share via email
*
* email options:
* email.modalId = id of the share via email modal div
* email.recipientsElementId = id of the recipients input
* email.subjectElementId = id of the subject input
* email.bodyElementId = id of the body text area
* email.shareElementId = id of the clickable element to wire the share click event to
* email.notificationElementId = id of a span that hold success and error messages
* email.defaultRecipients = a comma delimited list of default recipients
* email.defaultSubject = the default value to use for email subjects
* email.defaultBody =  the default value to use for email bodies, the url to share is appended to this as a new line
*/
function SharePage(options) {
	var config = {
		shareViaEmailEndpoint: "/umbraco/api/sharing/shareviaemail",
		shareViaEmailElementId: null,
		email: {
			modalId: "spEmailModal",
			recipientsElementId: "spEmailRecipients",
			subjectElementId: "spEmailSubject",
			bodyElementId: "spEmailBody",
			shareElementId: "spEmailShare",
			notificationElementId: "spEmailModalNotification",
			defaultRecipients: "",
			defaultSubject: document.title,
			defaultBody: ""
		}
	};

	if (options) {
		config.shareViaEmailEndpoint = options.shareViaEmailEndpoint ? options.shareViaEmailEndpoint : config.shareViaEmailEndpoint;
		config.shareViaEmailElementId = options.shareViaEmailElementId;

		if (options.email) {
			config.email = {
				modalId: options.email.modalId ? options.email.modalId : config.email.modalId,
				recipientsElementId: options.email.recipientsElementId
					? options.email.recipientsElementId
					: config.email.recipientsElementId,
				subjectElementId: options.email.subjectElementId
					? options.email.subjectElementId
					: config.email.subjectElementId,
				bodyElementId: options.email.bodyElementId ? options.email.bodyElementId : config.email.bodyElementId,
				shareElementId: options.email.shareElementId ? options.email.shareElementId : config.email.shareElementId,
				notificationElementId: options.email.notificationElementId
					? options.email.notificationElementId
					: config.email.notificationElementId,
				defaultRecipients: options.email.defaultRecipients
					? options.email.defaultRecipients
					: config.email.defaultRecipients,
				defaultSubject: options.email.defaultSubject ? options.email.defaultSubject : config.email.defaultSubject,
				defaultBody: options.email.defaultBody ? options.email.defaultBody : config.email.defaultBody
			};
		}
	}

	var blur = document.getElementById("spEmailModal");
	var email = null;
	var emailModal = null;
	var emailRecipientsElement = null;
	var emailSubjectElement = null;
	var emailBodyElement = null;
	var emailShareElement = null;

	if (config.shareViaEmailElementId) {
		email = document.getElementById(config.shareViaEmailElementId);
		emailModal = document.getElementById(config.email.modalId);
		emailRecipientsElement = document.getElementById(config.email.recipientsElementId);
		emailSubjectElement = document.getElementById(config.email.subjectElementId);
		emailBodyElement = document.getElementById(config.email.bodyElementId);
		emailShareElement = document.getElementById(config.email.shareElementId);
	}

	function init() {
		if (email) {
			email.addEventListener("click",
				function () {
					displaySharePageViaEmailModal(emailRecipientsElement.value, emailSubjectElement.value, emailBodyElement.value);
				});

			emailShareElement.addEventListener("click", function () {
				var emailModalNotificationElement = document.getElementById(config.email.notificationElementId);

				if (typeof emailRecipientsElement.value === "undefined" || emailRecipientsElement.value === null || emailRecipientsElement.value === "") {
					emailModalNotificationElement.innerHTML = "<h3>Recipients are required</h3>";
					return;
				}

				if (typeof emailSubjectElement.value === "undefined" || emailSubjectElement.value === null || emailSubjectElement.value === "") {
					emailModalNotificationElement.innerHTML = "<h3>Subject is required</h3>";
					return;
				}

				if (typeof emailBodyElement.value === "undefined" || emailBodyElement.value === null || emailBodyElement.value === "") {
					emailModalNotificationElement.innerHTML = "<h3>Message is required</h3>";
					return;
				}

				sharePageViaEmail(emailRecipientsElement.value, emailSubjectElement.value, emailBodyElement.value);
			});

			$("#share-page-close-modal").on("click", function () {
				emailModal.classList.add("hidden");
				blur.classList.remove("modal-open");
			});
		}
	}

	function displaySharePageViaEmailModal(recipients, subject, body) {
		subject = subject ? subject : config.email.defaultSubject;
		recipients = recipients ? recipients : config.email.defaultRecipients;
		body = body ? body : config.email.defaultBody;

		emailRecipientsElement.value = recipients;
		emailSubjectElement.value = subject;
		emailBodyElement.value = body;

		emailModal.classList.remove("hidden");
		blur.classList.add("modal-open");
	}

	function sharePageViaEmail(recipients, subject, body) {
		var request = new XMLHttpRequest();

		emailShareElement.disbled = true;

		request.onreadystatechange = function () {
			emailShareElement.disbled = false;
			var emailModalNotificationElement = document.getElementById(config.email.notificationElementId);

			if (this.readyState === 4 && this.status === 200) {
				if (this.responseText === "true") {
					emailModalNotificationElement.innerHTML = "<h2>Success!</h2>";

					//we hide the modal again
					setTimeout(function () {
						emailModal.classList.add("hidden");
						blur.classList.remove("modal-open");
					},
						3000);
				} else {
					emailModalNotificationElement.innerHTML = "<h2>Failure!</h2>";
				}
			} else if (this.status === 500) {
				emailModalNotificationElement.innerHTML = "<h2>Failure!</h2>";
			}
		};

		request.open("POST", config.shareViaEmailEndpoint, true);
		request.setRequestHeader("Content-Type", "application/json");
		request.send(JSON.stringify({
			contentId: window.ContentId,
			recipients: recipients,
			subject: subject,
			body: body
		}));
	}

	return {
		init: init,
		displaySharePageViaEmailModal: displaySharePageViaEmailModal,
		sharePageViaEmail: sharePageViaEmail
	};
}

SharePage({
	shareViaEmailElementId: "spEmail",
	shareViaEmailEndpoint: "/umbraco/api/Sharing/ShareViaEmail"
}).init();