function initContentCarousel() {
	$('.content-carousel-slides').each(function (index) {
		var mainSlider = $(this);
		var mainArrows = $(this).siblings('.carousel-controls');
		mainSlider.attr('id', 'contentCarousel' + index);
		mainArrows.attr('id', 'carouselControls' + index);

		mainSlider.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			dots: true,
			fade: true,
			autoplay: true,
			autoplaySpeed: 5000,
			prevArrow: '<span class="c-icon i-arrow prev-arrow-icon"></span>',
			nextArrow: '<span class="c-icon i-arrow next-arrow-icon"></span>',
			appendArrows: '#carouselControls' + index,
			appendDots: '#carouselControls' + index,
			responsive: [
				{
					breakpoint: 992,
					settings: {
					  adaptiveHeight: true,
						autoplay: false
					}
				}
			]
		});
	});
}

function initCarousel(){
	$('.carousel__slides').each(function(index){
		var mainSlider = $(this);
		var mainArrows = $(this).siblings('.carousel-controls');


		mainSlider.attr('id', 'carousel' + index);
		mainArrows.attr('id', 'carouselControls' + index);

		mainSlider.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			dots: true,
			fade: true,
			autoplay: true,
			autoplaySpeed: 5000,
			appendDots: '#carouselControls' + index
		});

	})
}



$(document).ready(function () {
	if ($('.content-carousel').length) {
		initContentCarousel();
	}
	if ($('.carousel').length) {
		initCarousel();
	}
});