/*
 the following global variables are declared on the site specific master page:
 consentPropertyName
 eabPropertyName
*/

var cookieStorage = {
	getItem: function getItem(item) {
		var name = item + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return undefined;
	},
	setItem: function setItem(item, value, exp) {
		if (exp) {
			document.cookie = item + '=' + value + ';expires=' + exp.toUTCString() + ';path=/;Secure;sameSite=strict;';
		} else {
			document.cookie = item + '=' + value + ';path=/;Secure;sameSite=strict;';
		}
	}
};

var saveToStorage = function saveToStorage(propertyName, exp) {
	if (exp != null) {
		return cookieStorage.setItem(propertyName, true, exp);
	}

	return cookieStorage.setItem(propertyName, true);
};

var shouldShowConsent = function () {
	return !cookieStorage.getItem(consentPropertyName);
};

var shouldShowEab = function () {
	return !cookieStorage.getItem(eabPropertyName);
};

var initConsent = function () {
	var consentPopup = document.getElementById('cookie-consent');

	var acceptFn = function () {
		var exp = new Date();
		exp.setFullYear(exp.getFullYear() + 1);
		saveToStorage(consentPropertyName, exp);

		consentPopup.classList.add('is-hidden');
	};

	var acceptBtn = document.getElementById('cookie-accept');
	acceptBtn.addEventListener('click', acceptFn);

	if (shouldShowConsent()) {
		consentPopup.classList.remove('is-hidden');
	}
}

var initEab = function () {
	var eab = document.getElementById('eab');
	if (typeof eab !== 'undefined' && eab != null) {
		if (shouldShowEab()) {
			eab.classList.remove('is-hidden');
			resizeEmergencyBanner();
		}

		var closeBtn = document.getElementById('close-eab');
		if (typeof closeBtn !== 'undefined' && closeBtn != null) {
			closeBtn.addEventListener('click', function () {
				saveToStorage(eabPropertyName);
				eab.classList.add('is-hidden');
				resizeEmergencyBanner();
			});
		}
	}
};

window.onload = function () {
	initConsent();
	initEab();
};