var percentageSlider = {
	init: function () {
		$('[data-percentage-slider]:not(.js-percentage-slider-done)').each(function() {
			var $this = $(this);
			var width = $this.attr('data-width');
			$this.animate({
				width: width
			}, 'slow');
			$this.addClass('js-percentage-slider-done');
		});
	}
};
$(function () {
	percentageSlider.init();
});