var countTo = {
	init: function () {
		$('[data-count-to]:not(.js-count-to-done)').each(function () {
			var $this = $(this);
			var dataFormatter = typeof $this.attr('data-formatter') !== 'undefined' ? $this.attr('data-formatter') : '';
			$this.countTo({
				formatter: countTo.processFormatter(dataFormatter)
			});
			$this.addClass('js-count-to-done');
		});
	},
	processFormatter: function(value) {
		if (value === 'spanFormatter') {
			return countTo.spanFormatter;
		}

		if (value === 'spanWithCommaFormatter') {
			return countTo.spanWithCommaFormatter;
		}

		return countTo.defaultFormatter;
	},
	defaultFormatter: function (value, options) {
		return value.toFixed(options.decimals);
	},
	spanWithCommaFormatter: function (value, options) {
		var formattedValue = parseInt(value.toFixed(options.decimals), 10).toLocaleString('en-US');
		var spans = [];
		for (var i = 0; i < formattedValue.split('').length; i++) {
			var span = $('<span />').html(formattedValue[i]);
			spans.push(span);
		}
		return spans;
	},
	spanFormatter: function (value, options) {
		var formattedValue = value.toFixed(options.decimals);
		var spans = [];
		for (var i = 0; i < formattedValue.split('').length; i++) {
			var span = $('<span />').html(formattedValue[i]);
			spans.push(span);
		}
		return spans;
	}

};
$(function () {
	countTo.init();
});