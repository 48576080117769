//https://www.sitepoint.com/build-javascript-countdown-timer-no-dependencies/
var countdown = {
	init: function () {
		$('[data-countdown]:not(.js-countdown-clock-done)').each(function () {
			var $this = $(this);
			countdown.initializeClock($this);
			$this.addClass('js-countdown-clock-done');
		});
	},
	initializeClock: function (clock) {
		var endTime = new Date(Date.parse(clock.attr('data-end-date')));
		var $days = clock.find('.days');
		var $hours = clock.find('.hours');
		var $minutes = clock.find('.minutes');
		var $seconds = clock.find('.seconds');

		var timeInterval = setInterval(updateClock, 1000);

		function updateClock() {
			var t = countdown.getTimeRemaining(endTime);

			countdown.processDigits($days, t.days);
			countdown.processDigits($hours, t.hours);
			countdown.processDigits($minutes, t.minutes);
			countdown.processDigits($seconds, t.seconds);

			if (t.total <= 0) {
				clearInterval(timeInterval);
			}
		}

		updateClock();
	},
	getTimeRemaining: function (endTime) {
		var date = new Date();

		var serverDateTime = new Date(new Date().toLocaleString('en-US', {
			timeZone: "Australia/Sydney"
		}));

		var diff = date.getTime() - serverDateTime.getTime();

		var startDate =  new Date(date.getTime() - diff);

		var total = Date.parse(endTime) - startDate;
		var seconds = Math.floor((total / 1000) % 60);
		var minutes = Math.floor((total / 1000 / 60) % 60);
		var hours = Math.floor((total / (1000 * 60 * 60)) % 24);
		var days = Math.floor(total / (1000 * 60 * 60 * 24));

		return { total: total, days: days, hours: hours, minutes: minutes, seconds: seconds };
	},
	processDigits: function(span, value) {
		var values = ('0' + value).slice(-2).split('');
		var spans = span.find('span');
		$(spans[0]).html(values[0]);
		$(spans[1]).html(values[1]);
		return;
	}
};
$(function () {
	countdown.init();
});