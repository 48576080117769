var accordion = {
	init: function () {
		$('div.accordion:not(.js-accordion-done)').each(function () {
			var $this = $(this);
			var $buttons = $this.find('button');
			var $panels = $this.find('div.accordion__panel');
			var $activePanel = $this.find('div.accordion__panel--active');
			$panels.hide();
			$activePanel.slideDown();
			$buttons.each(function() {
				var $this = $(this);

                var panelId = $this.attr('aria-controls');
			    var $panel = $('#' + panelId);
				$this.click(function () {
					if (!$this.hasClass('accordion__btn--active')) {
						$buttons.removeClass('accordion__btn--active');
						$panels.removeClass('accordion__panel--active');
						$panels.slideUp();
						$this.addClass('accordion__btn--active');
						$panel.slideDown();
					} else if ($this.hasClass('accordion__btn--active')) {
                        $this.removeClass('accordion__btn--active');
						$panel.slideUp();
					}
				});
			});
			$this.addClass('js-accordion-done');
		});
	}
};
$(function () {
	accordion.init();
});