var urlParameters = new URLSearchParams(window.location.search);

var checkboxes = document.querySelectorAll(
  ".gg-filter-sort input[type='checkbox'], #filter-modal input[type='checkbox']"
);
var radios = document.querySelectorAll(
  ".gg-filter-sort input[type='radio'], #filter-modal input[type='radio']"
);
var radioDefault = document.querySelector(".gg-filter-sort #default");

var radioDefaultMobile = document.querySelector(
  "#filter-modal #default-mobile"
);

var selectAlls = document.querySelectorAll(
  ".gg-filter-sort .select-all, #filter-modal .select-all"
);

var sortByFilters = document.querySelectorAll("#gift-sort, #gift-sort-mobile");

var clear = document.querySelector(".gg-filter-sort .clear-btn-wrapper");
var reset = document.querySelector(".reset-filter");

sortByFilters.forEach(function(sortByFilter) {
  sortByFilter.addEventListener("change", function(e) {
    sortByFilter.querySelector(
      "p"
    ).innerText = e.target.parentElement.querySelector("label").innerText;
  });
});

selectAlls.forEach(function(el) {
  function getCheckboxes() {
    var allCheckboxes = el.parentElement.querySelectorAll(
      'input[type="checkbox"]'
    );
    var checkedCheckboxes = el.parentElement.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    return {
      all: allCheckboxes,
      checked: checkedCheckboxes,
    };
  }

  function updateCheckboxes() {
    var state = getCheckboxes();
    if (state.all.length === state.checked.length) {
      el.innerText = "Unselect all";
      el.classList.add("selected-all");
    } else {
      el.innerText = "Select all";
      el.classList.remove("selected-all");
    }
  }
  updateCheckboxes();
  el.parentElement.addEventListener("change", updateCheckboxes);

  function uncheckedAll() {
    var state = getCheckboxes();
    state.all.forEach(function(checkbox) {
      checkbox.checked = false;
      var event = new Event("change", { bubbles: true });
      checkbox.dispatchEvent(event);
    });
  }

  function checkedAll() {
    var state = getCheckboxes();
    state.all.forEach(function(checkbox) {
      checkbox.checked = true;
      var event = new Event("change", { bubbles: true });
      checkbox.dispatchEvent(event);
    });
  }

  document.querySelector(".clear-btn").addEventListener("click", function() {
    var state = getCheckboxes();
    if (state.all.length === state.checked.length) {
      uncheckedAll();
    }
  });

  el.addEventListener("click", function(e) {
    var state = getCheckboxes();
    if (state.all.length === state.checked.length) {
      uncheckedAll();
    } else {
      checkedAll();
    }
  });
});

checkboxes.forEach(function setupElement(el) {
  el.addEventListener("change", function(e) {
    if (e.target.checked) {
      var current = urlParameters.get(e.target.name);
      if (current) {
        var currentValues = current.split(",");
        currentValues.push(e.target.value);
        var newValue = currentValues.join(",");
        urlParameters.set(e.target.name, newValue);
      } else {
        urlParameters.set(e.target.name, e.target.value);
      }
    } else {
      var newParams = [];
      var current = urlParameters.get(e.target.name);
      if (current) {
        var currentValues = current.split(",");
        currentValues.forEach(function(param) {
          if (param !== e.target.value) {
            newParams.push(param);
          }
        });
        if (newParams.length > 0) {
          urlParameters.set(e.target.name, newParams.join(","));
        } else {
          urlParameters.delete(e.target.name);
        }
      } else {
        urlParameters.delete(e.target.name);
      }
    }
    if ($(window).width() > 991) {
      updateGiftList();
    }
  });
});

radios.forEach(function setupElement(el) {
  el.addEventListener("change", function(e) {
    if (e.target.name == "sorting" && e.target.value) {
      var values = e.target.value.split("-");
      var sortBy = values[0];
      var orderBy = values[1];
      if (e.target.checked) {
        urlParameters.set("sortBy", sortBy);
        urlParameters.set("orderBy", orderBy);
      } else {
        if (
          urlParameters.get("sortBy") === sortBy &&
          urlParameters.get("orderBy") === orderBy
        ) {
          urlParameters.delete("sortBy");
          urlParameters.delete("orderBy");
        }
      }
    } else {
      urlParameters.delete("sortBy");
      urlParameters.delete("orderBy");
    }

    if ($(window).width() > 991) {
      updateGiftList();
    }
  });
});

var filterTimeout;
function updateGiftList() {
  clearTimeout(filterTimeout);

  filterTimeout = setTimeout(function() {
    var windowsDesktop =
      window.location.pathname + "?" + urlParameters.toString();
    fetch(windowsDesktop)
      .then(function(r) {
        return r.text();
      })
      .then(function(text) {
        var parser = new DOMParser();
        var doc = parser.parseFromString(text, "text/html");
        var selector = "#shop-global-gifts .gg-category-listing";
        var sourceNode = doc.querySelector(selector);
        var targetNode = document.querySelector(selector);
        while (targetNode.firstChild) {
          targetNode.removeChild(targetNode.firstChild);
        }
        while (sourceNode.firstChild) {
          targetNode.appendChild(sourceNode.firstChild);
        }
      });
    history.pushState(null, "", windowsDesktop);
  }, 200);
}

var resultsTimeout;

function getTotalResults() {
  clearTimeout(resultsTimeout);

  resultsTimeout = setTimeout(function() {
    var windowsDesktop =
      window.location.pathname + "?" + urlParameters.toString();
    fetch(windowsDesktop)
      .then(function(r) {
        return r.text();
      })
      .then(function(text) {
        var parser = new DOMParser();
        var doc = parser.parseFromString(text, "text/html");
        var selectorLists = "#shop-global-gifts .gg-gift-tile";
        var listsNode = doc.querySelectorAll(selectorLists);

        if (listsNode.length == 0) {
          reset.classList.remove("reset-filter-hidden");
        } else {
          reset.classList.add("reset-filter-hidden");
        }

        var radios = ".gg-filter-sort input[type='radio']:checked";
        var sortByFilter = "#shop-global-gifts #gift-sort";
        var sortByFilterMobile = "#filter-modal #gift-sort-mobile";
        var radioList = doc.querySelector(radios);
        var sortByFilters = document.querySelector(sortByFilter);
        var sortByFiltersMobile = document.querySelector(sortByFilterMobile);

        sortByFilters.querySelector(
          "p"
        ).innerText = radioList.parentElement.querySelector("label").innerText;
        sortByFiltersMobile.querySelector(
          "p"
        ).innerText = radioList.parentElement.querySelector("label").innerText;
      });
  }, 200);
}

if (clear) {
  function getCheckboxes() {
    var allCheckboxes = clear.parentElement.querySelectorAll(
      'input[type="checkbox"]'
    );
    var checkedCheckboxes = clear.parentElement.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    return {
      all: allCheckboxes,
      checked: checkedCheckboxes,
    };
  }

  function getDefaultRadio() {
    return {
      default: radioDefault,
    };
  }

  function updateInputs() {
    var state = getCheckboxes();
    var stateRadio = getDefaultRadio();

    if (state.checked.length === 0 && stateRadio.default.checked) {
      clear.classList.add("clear-btn-hidden");
    } else {
      clear.classList.remove("clear-btn-hidden");
    }
    getTotalResults();
  }
  getTotalResults();

  updateInputs();

  function clearFunction() {
    Array.from(urlParameters.keys()).forEach(function(key) {
      urlParameters.delete(key);
    });
    checkboxes.forEach(function(el) {
      el.checked = false;
    });

    radioDefault.checked = true;

    updateGiftList();
  }

  function onClick() {
    clearFunction();
    updateInputs();
  }

  function onReset() {
    clearFunction();
    updateInputs();

    if ($(window).width() <= 991) {
      radioDefaultMobile.checked = true;
    } else {
      radioDefault.checked = true;
    }
  }

  clear.parentElement.addEventListener("change", updateInputs);

  document.querySelector(".clear-btn").addEventListener("click", onClick);

  document.querySelector(".reset-filter").addEventListener("click", onReset);
}

$(function() {
  //filters.init();
  $(".gg-filter").each(function() {
    var listItem = $(this);
    var link = listItem.children(".select-btn");
    link.click(function(e) {
      e.preventDefault();

      var $sublist = $(this).siblings(".select-options");
      $(".select-options")
        .not($sublist)
        .removeClass("select-options-show");

      $sublist.toggleClass("select-options-show");
    });
  });

  $(document).click(function(event) {
    // Check if the clicked element is inside or outside of the div
    if (!$(event.target).closest(".gg-filter").length) {
      // Hide the div if the clicked element is outside of it
      $(".select-options").removeClass("select-options-show");
    }
  });

  $("#btn-filter-modal").click(function() {
    $("#filter-modal").removeClass("modal-hidden");
  });

  $("#close-modal").click(function() {
    $("#filter-modal").addClass("modal-hidden");
  });

  $("#modal-overlay").click(function() {
    $("#filter-modal").addClass("modal-hidden");
  });

  $("#btn-search-result").click(function(e) {
    e.preventDefault();
    $("#filter-modal").addClass("modal-hidden");

    if ($(window).width() <= 991) {
      updateGiftList();
      getTotalResults();
    }
  });
});
