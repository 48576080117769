var formDonation = {
	init: function () {
		$('.donation-form:not(.js-done)').each(function () {
			var $this = $(this);

			var $form = $this.find('form');
			var $validator = $form.validate();
			var $errors = $form.find(".field-validation-error span");

			var $frequencies = $this.find('div.donation-form__frequency input[type=radio]');
			var $suggestedDonationsItems = $this.find('div.donation-form__suggested-donations');
			var $suggestedDonationsInputItems = $this.find('div.donation-form__suggested-donations input[type=radio]');
			var $suggestedDonationsContentItems = $this.find('div.donation-form__suggested-donations__content');

			var $otherAmount = $this.find('input[type=number].form-money-input');
			var $otherAmountContent = $this.find('div.donation-form__other-amount__content');
			var $donationAmount = $this.find('input[type=number].donation-form__amount');

			$frequencies.each(function () {
				var $this = $(this);
				$this.click(function () {
					var key = $this.attr('data-key');
					$suggestedDonationsItems.hide();
					var suggestedDonations = $('#suggestedDonations-' + key);
					suggestedDonations.show();
					var $defaultSuggestedDonationsInput = suggestedDonations.find('input[type=radio][data-default="true"]');
					$defaultSuggestedDonationsInput.click();
				});
			});

			$suggestedDonationsItems.each(function() {
				var $this = $(this);
				var $suggestedDonationsInputs = $this.find('input[type=radio]');
				var $suggestedDonationsContent = $this.find('div.donation-form__suggested-donations__content');
				var $suggestedDonationsText = $this.find('div.donation-form__suggested-donations__text');

				$suggestedDonationsInputs.each(function () {
					var $this = $(this);
					$this.click(function () {
						$suggestedDonationsInputs.removeAttr('data-default');
						$this.attr('data-default', 'true');
						$suggestedDonationsText.html($this.attr('data-text'));
						$suggestedDonationsContent.show();
						$otherAmount.val('');
						$otherAmountContent.hide();
						$donationAmount.val($this.val());
						$errors.each(function () { $validator.settings.success($(this)); });
						$validator.resetForm();
					});
				});
			});

			var validateOtherAmount = function () {
				$suggestedDonationsContentItems.hide();
				$suggestedDonationsInputItems.prop("checked", false);
				$otherAmountContent.css('display', 'flex');
				if ($otherAmount.hasClass('input-validation-error')) {
					$donationAmount.val('');
				} else {
					$donationAmount.val($otherAmount.val());
				}
				$errors.each(function () { $validator.settings.success($(this)); });
				$validator.resetForm();
				$donationAmount.valid();
			}

			$otherAmount.on("input", validateOtherAmount);
			$otherAmount.on("blur", validateOtherAmount);

			$this.addClass('js-done');
		});
	}
};

$(function () {
	formDonation.init();
});