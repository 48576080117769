function initTingle(videoType, videoLink) {

	var modal = new tingle.modal({
		footer: true,
		stickyFooter: false,
		closeMethods: ['overlay', 'button', 'escape'],
		closeLabel: "Close",
		cssClass: ['tingle-video-container'],
		onClose: function () {
			modal.destroy();
		}
	});

	var content = false;
	if (videoType === "youtube") {
		content = '<iframe class="tingle-video" src="' + videoLink + '"  frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"  allowfullscreen></iframe>';
	}
	else if (videoType === "vimeo") {
		content = '<iframe class="tingle-video" src="' + videoLink + '" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
	}
	else {
		content = '<iframe class="tingle-video" src="' + videoLink + '" frameborder="0"></iframe>';
	}

	if (content !== false) {
		modal.setContent(content);
		modal.open();
	}
}

$(document).ready(function () {
	if ($('.tingle-video-link').length) {
		$('.tingle-video-link').click(function () {
			var type = $(this).data('type');
			var link = $(this).data('video');
			initTingle(type, link);
		});
	}
});